.circ-progress-card {
  position: relative;
}

.circ-progress-card:hover {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 50%), 0px 15px 20px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  transform: rotate(-1deg) scale(1) translate(0px, -6px);
}

.circ-progress-card .MuiCircularProgress-root {
  /*width: auto !important;*/
  height: auto !important;
  /*margin: 16px 0;*/
  /*padding: 164px;*/
}

.circ-progress-card svg {
  width: 188px !important;
}

.circ-progress-text-card {
  height: 124px;
  margin-top: 202px;
}

.circ-progress-text-card div {
  font-size: 48px;
  font-weight: 100;
  opacity: .8;
}

.circ-description {
  font-size: 12px;
  margin: 16px 0 0 0;
  opacity: .5;
}
