.rtm-hero {
  /*background-image: url(https://images.unsplash.com/photo-1677784976102-cb1513d3e322?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2456&q=80);*/
  /*background-image: url(https://images.unsplash.com/photo-1590004828948-09db7606e5ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2052&q=80);*/
  /*background-image: url(https://images.unsplash.com/photo-1618166093881-b41b48cf1f9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80);*/
  /*background-image: url(https://images.unsplash.com/photo-1655635131633-a36ecd7f5b33?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80);*/
  background-image: url(https://plus.unsplash.com/premium_photo-1675470805815-8999a8da65f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80);
  background-size: cover;
  background-position: center center;
  min-height: 280px;
  position: relative;
}

.rtm-hero h2 {
  color: white;
  z-index: 100;
  letter-spacing: 6px;
}

.rtm-hero .overlay {
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.value-badge {
  position: absolute;
  font-size: 14px;
  opacity: 1;
  font-weight: 300;
  z-index: 10;
  width: 16px;
  height: 16px;
  background: white;
  padding: 2px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f1f1f1;
  color: #999;
}

.grid-wrap {
  position: relative;
  opacity: 0;
  /*transition: all 0.1s ease-in-out;*/
}

.grid-wrap .MuiIconButton-root {
  background: #f3f3f3 !important;
  transition: all 0.2s ease-in-out;
}

.grid-wrap .MuiIconButton-root:hover {
  /*background: rgb(140, 255, 143) !important;*/
}

.MuiDataGrid-cell {
  position: relative;
}

.MuiDataGrid-columnHeaderTitle {
  /*padding: 8px;*/
}

.edit-icon svg {
  width: 16px;
}

.grid-wrap .status-flag {
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  width: auto;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 0 8px 0 0;
}

.grid-wrap .flag-id{
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: absolute;
  bottom: 0px;
  left: 0;
  font-size: 8px;
  font-weight: 400;
  width: auto;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 0 8px 0;
  z-index: 101;
}

.grid-wrap .MuiDataGrid-columnHeaderTitleContainer {
  margin: 0 !important;
}

.grid-wrap .bar-block {
  width: 16px;
  height: 16px;
  margin: 2px;
  border-radius: 4px;
}

.loading-state {
  opacity: .5;
  filter: grayscale(1);
  transition: all .3s ease-in-out;
}

.loaded-state {
  opacity: 1;
  filter: none;
  transition: all .3s ease-in-out;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

/*.Mui-focused {*/
/*  outline: none !important;*/
/*  background: red;*/
/*}*/

.MuiDataGrid-cell--editing {
  /*border-radius: 20px !important;*/
}

.MuiDataGrid-cell--editing {
  outline: solid green 0px !important;
  /*border-radius: 0 !important;*/
}

.MuiDataGrid-cell--editing:focus-within {
  /*outline: solid green 0px !important;*/
  /*border-radius: 0 !important;*/
}

.MuiDataGrid-cell--editing div {
  /*outline: solid red 0px !important;*/
  /*border-radius: 0 !important;*/
  /*width: 100%;*/
}

.grid-wrap .MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.MuiOutlinedInput-input {
  outline: none !important;
}

.explanation {
  white-space: normal;
  margin: 0 24px 0 0;
  width: 100%;
  opacity: .5;
  font-size: 12px;
}

.circ-progress-card .rank {
  position: absolute;
  color: #999 !important;
  top: 120px;
  right: calc(20% - 20px);
  font-size: 15px !important;
  font-family: 'Courgette', cursive;
  opacity: .8;
  padding: 9px 10px 8px 8px;
  border-radius: 24px;
  background: #fff;
  z-index: 100;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  /*width: 32px;*/
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800 !important;
  box-shadow: -2px 3px 0px -1px rgba(0,0,0,.2);
}

.circ-progress-card .rank:hover {
  box-shadow: -2px 3px 0px -1px rgba(0,0,0,.2) !important;
}

.filters-wrap {
  margin: 0 0 -48px 0;
  /*overflow: hidden;*/
  position: relative;
}

.filters-wrap p {
  font-size: 14px !important;
}

.mainSpinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 66px;
  left: 80px;
  background: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1));
  z-index: 100;
  min-height: 620px;
  width: 100%
}

.mainSpinner div {
  margin-left: -112px;
}

.mainSpinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 66px;
  left: 80px;
  background: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1));
  z-index: 100;
  min-height: 620px;
  width: 100%
}

.mainSpinner div {
  margin-left: -112px;
}

.filter-icon {
  cursor: pointer;
  padding: 8px 8px 4px 8px;
  border-radius: 24px;
  color: #999;
  background: #f1f1f1;
  transition: all .2s ease-in-out;
}

.color-blue {
  background: #2196f3 !important;
  color: white;
  transition: all .2s ease-in-out;
  transform: rotate(180deg);
}

.show-filters {
  opacity: 1 !important;
  height: 240px;
  transition: all .2s ease-in-out;
}

.hide-filters {
  opacity: 0 !important;
  height: 0px;
  transition: all .2s ease-in-out;
}

.acc-summary div:first-child {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  /*margin: 0 8px 0 0;*/
}
.swiper-slide {
  display: flex;
}

.controls-wrap {
  position: absolute;
  top: -72px;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
}

#saveData {
  height: 40px;
  width: 164px;
}

#addPartner {
  z-index: 11;
  height: 40px;
  margin: 0 8px 0 0;
}

.scored-retailers {
  font-size: 11px;
  margin: 2px -4px 0 0;
  opacity: .6;
}

.all-switch {
  margin: 2px 0 0 0 !important;
}

.top-curve {
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .show-filters {
    height: auto;
  }

  .padding-24 {
    padding: 8px;
  }
}