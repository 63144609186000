.home-cards {
  flex-wrap: wrap;
  justify-content: flex-start !important;
}
.divider {
  height: 1px;
  background: #e9e9e9;
  margin: 32px 84px 0;
}

.circ-progress span {
  width: auto !important;
  height: auto !important;
}

.circ-progress svg {
  width: 224px !important;
}

.circ-progress-text div {
  font-size: 64px;
  font-weight: 100;
}

.circ-description {
  font-size: 12px;
  margin: 16px 0 0 0;
  opacity: .5;
}

.home-wrapper {
  position: relative;
  z-index: 10;
  /*background: white;*/
  margin: -24px 42px 64px 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  /*border: 1px solid #e9e9e9;*/
  padding: 64px;
  /*min-height: 540px;*/
  /*box-shadow: 8px 8px 0 0 rgb(0 0 0 / 4%);*/
}

.drop-select-wrapper {
  position: absolute;
  right: 64px;
  top: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.fade-in {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.invisible {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

@media screen and (max-width: 960px) {
  .home-wrapper {
    padding: 0;
  }
}
