.scatterplotCircle {
  fill-opacity: .7;
  stroke-width: .5px;
  stroke: "white";
}

.dimmed {
  opacity: 0;
  transition: opacity;
  transition-delay: .5s;
  transition-duration: .5s;
}

.scatterplotCircle:hover {
  cursor: pointer;
  opacity: 1;
  stroke: black;
  fill-opacity: 1;
  stroke-width: 2px;
}

