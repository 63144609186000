.competitive-landscape-container {
    width: 100%;
    height: 100%;
}

.competitive-landscape-header {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.header {
    display: flex;
    justify-content: space-between;
}

.data-grid-container {
    display: flex;
    justify-content: center;
    width: 95%;
    height: 400px;
    padding: 48px 0 0 0;
}

.market-share-header {
    font-weight: 800;
    margin-left: auto;
    margin-right: 0;
}