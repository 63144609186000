.dsp-hero {
  background-image: url(https://images.unsplash.com/photo-1597733336794-12d05021d510?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2148&q=80);
  background-size: cover;
  background-position: center center;
  min-height: 300px;
  position: relative;
}

.dsp-hero h2 {
  color: white;
  z-index: 100;
}

.overlay {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.MuiDataGrid-root {
  border: 0 !important;
}

.cell-title {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: .4;
}

.cell-description {
  font-size: 12px;
  font-weight: 400;
  opacity: .6;
  white-space: initial;
  text-align: right;
  margin-top: 8px;
}

.cell-value {
  font-size: 36px;
  color: #8b84af;
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
}

.MuiDataGrid-footerContainer {
  /*display: none !important;*/
}

.MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  /*justify-content: center;*/
  color: #666 !important;
  font-weight: 300 !important;
  width: 100%;
  margin: 0 14px 0 4px;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100%;
}

.MuiDataGrid-columnHeaderTitle {
  /* color: white; */
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
  text-transform: uppercase;
}

.MuiDataGrid-pinnedColumnHeaders {
  border-bottom: 1px solid #d8d8d8;
}

.MuiDataGrid-cellContent {
  font-size: 14px;
  /*opacity: .5 !important;*/
  font-weight: 400 !important;
  text-transform: uppercase;
  overflow: visible !important;
}

.lalala {
  padding: 0 8px;
}

.bid-grid .status-flag {
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  width: auto;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 0 8px 0 0;
}
