.hideProgressBar {
    margin-top: 24px;
    height: 4px;
    position: relative;
    display: block;
    width: 100px;
    background: #E7EAEE;
    border-radius: 4px;
}
.full-screen-modal::part(content) {
    overflow: auto !important;
}