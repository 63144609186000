@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

html, body {
  height: 100%;
}

#root {
  height: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.page-wrapper {
  margin-left: 80px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.linear-loader {
  position: absolute !important;
  /*top: 562px;*/
  top: 324px;
  width: 100px !important;
  z-index: 2000 !important;
  left: calc(50% - 10px);
  border-radius: 6px;
  height: 12px;
}

@keyframes flasher {
  from {
    margin-top: 24px;
  }
  to {
    margin-top: 24px;
  }
}

.card {
  animation: flasher 1s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .mob-only {
    display: none !important;
  }
  .page-wrapper {
    margin-left: 0px;
  }
  .linear-loader {
    position: absolute !important;
    /*top: 562px;*/
    top: 108px;
    width: 100px !important;
    z-index: 2000 !important;
    left: calc(50% - 50px);
    border-radius: 6px;
    height: 12px;
  }

}
