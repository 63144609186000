/*Grid*/

.max-width-1440 { max-width: 1440px; }

div[class*='col-'] { display: flex; justify-content: center; box-sizing: border-box; }

.row { display: flex; flex-wrap: nowrap;}

.col-1-1 { width: 100%; }
.col-1-2 { width: 50%; }
.col-1-3 { width: 33.33%; }
.col-1-4 { width: 25%; }
.col-1-5 { width: 20%; }
.col-2-3 { width: 66.66%; }
.col-3-5 { width: 60%; }
.col-4-5 { width: 80%; }
.col-auto { width: auto; }

.flex-direction-column { flex-direction: column; }
.flex-direction-row { flex-direction: row; }
.flex-align-start { align-items: flex-start; }
.flex-align-end { align-items: flex-end; }
.flex-align-center { align-items: center; }
.flex-justify-start { justify-content: flex-start !important; }
.flex-justify-end { justify-content: flex-end !important; }
.flex-justify-center { justify-content: center !important; }
.flex-center-all { justify-content: center; align-items: center; }


.padding-16 { padding: 16px; }
.padding-24 { padding: 24px; }
.padding-48 { padding: 48px; }
.padding-64 { padding: 64px; }
.padding-128 { padding: 128px; }
.padding-lr-224 { padding: 0 224px; }
.padding-lr-64 { padding: 0 64px; }
.padding-right0 { padding-right: 0 !important; }
.padding-right24 { padding-right: 24px; }
.padding-right48 { padding-right: 48px; }
.padding-right64 { padding-right: 64px; }
.padding-left48 { padding-left: 48px; }
.padding-left64 { padding-left: 64px; }
.padding-left96 { padding-left: 96px; }
.margin-t-negative-48 { margin-top: -48px; }
.margin-t-negative-96 { margin-top: -96px; }
.margin-t-16 { margin-top: 16px; }
.margin-t-64 { margin-top: 64px; }
.margin-t-42 { margin-top: 42px; }
.margin-t-96 { margin-top: 96px; }
.margin-t-196 { margin-top: 196px; }
.margin-bt-64 { margin: 64px 0; }
.margin-b-64 { margin: 0 0 64px 0; }
.margin-l-24 { margin-left: 24px; }

.bump-up { z-index: 10; }
.bump-up-more { z-index: 11; }

.bg-light-gray {
  background: #f9f9f9;
}

@media (max-width: 768px) {
  .padding-lr-64 {
    padding: 8px;
  }
}
