h1, h2, h3, h4, h5, h6, p {
  font-family: 'Roboto', sans-serif;
  color: #555;
  line-height: 1.2em;
}

h1 {
  font-size: 80px;
  font-weight: 600;
  margin: 0;
}

h2  {
  font-size: 48px;
  font-weight: 100;
  margin: 0;
}

h3 {
  font-size: 32px;
  font-weight: 200;
  margin: 0;
}

h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 1.4em;
  color: #555;
}


@media (max-width: 1280px) {
  h1 {
    font-size: 64px;
  }
}
