.h2 {
  color: #404252;
  font-size: 28px;
  font-family: 'Roboto';
  font-weight: 600;
}

.h4 {
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 800;
}

.h5 {
  color: #4D5358;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 800;
}

.h6 {
  color: #66676C;
  font-size: 14px;
  font-family: "Roboto";
}

.exp-head-wrap {
    margin: 84px 0 40px 0;
    padding: 0 64px;
    box-sizing: border-box;
    z-index: 2;
    background: white;
}

.exp-det-wrap {
    margin: 0;
    padding: 42px 64px;
    box-sizing: border-box;
    z-index: 3;
    background: white;
}

.exp-box {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
    margin: 16px;
    padding: 16px;
    border-radius: 4px;
    min-height: 196px;
    background: white;
    border-top: 4px solid #323d68;
}

.exp-box-within {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
    margin: 0 0 32px 0;
    padding: 16px;
    border-radius: 4px;
    min-height: 196px;
    background: white;
    border-top: 4px solid #323d68;
}

.exp-box-within:last-child {
    margin: 0;
}

.exp-box h3 {
    margin: 16px 0;
    font-weight: 400;
    font-size: 28px;
    text-align: center;
}

.exp-box h5 {
    margin: 16px 0;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.exp-grid-pad {
    padding: 12px 0;
}

.exp-market {
    background: #323d68;
    padding: 16px;
    border-radius: 0 0 34px 0;
    top: 0;
    position: absolute;
    left: 0;
    color: white;
}

.exp-market-name {
    font-size: 12px;
    margin: 3px 2px 0 0;
    text-transform: uppercase;
}

.exp-market-code {
    letter-spacing: 1px;
    font-weight: 700;
    padding: 4px;
    font-size: 24px;
    border-radius: 20px;
}

.dropdown-wrap {
    margin: -12px 0 0 0;
    display: flex;
    flex-direction: column;
}

.select-container {
    margin-top: 8px;
}

.container {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.chart-container {
    width: 100%;
    height: 175px;
    margin-top: 10px;
}

.tick-text {
    font-size: 14px;
    fill: #444444;
}

.bar-label {
    font-size: 14px;
    fill: #444444;
}
