.time-machine-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 365px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 11;
}

.time-machine {
  width: 320px;
  height: 100%;
  background: #f9f9f9;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
  z-index: 10002;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: auto;
}

.time-machine-on {
  -webkit-animation: fadein .4s; /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation-timing-function: ease-in-out;
    animation: fadein .4s;
    animation-timing-function: ease-in-out;
}

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    0% {
      opacity: .5;
      -webkit-transform: translateX(365px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }
  
  @keyframes fadein {
    0% {
      opacity: .5;
      transform: translateX(365px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .fadein {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
  }

.time-machine-off {
  -webkit-animation: fadeOutRight .4s; /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation-timing-function: ease-in-out;
    animation: fadeOutRight .4s;
    animation-timing-function: ease-in-out;
}

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeOutRight {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
    100% {
      opacity: .5;
      -webkit-transform: translateX(365px);
    }
  }

  @keyframes fadeOutRight {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: .5;
      transform: translateX(365px);
    }
  }

  .fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
  }

.time-machine-header {
  font-weight: 300;
  font-size: 24px;
  background: white;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-machine-header h4 {
  height: 64px;
  font-weight: 300;
  letter-spacing: 8px;
  margin: 0 0 0 16px;
  opacity: .7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.time-machine-commit {
  font-size: 16px;
  margin: 8px 12px 0 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 2px 2px 6px;
  border-radius: 6px;
}

.time-machine-menu {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 0 0 2px;
}

.time-machine-menu:hover {
  background: #ebf0ff;
}

.time-machine-commit span {
  margin: 0 2px 0 4px;
}

.time-machine-header-close {
  width: 16px;
  background: #1a90ff;
  padding: 4px 4px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0 24px;
  cursor: pointer;
  color: white;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.4);
}

.time-machine-header-close:hover {
  background: #3fa0ff;
}

.active-commit {
  background: #ebf0ff;
}

.pending-commit {
  font-style: italic;
}

.pending-commit span {
  color: #999;
}
