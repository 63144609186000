.net-sales-by-category-container {
    width: 100%;
    height: 100%;
}

.net-sales-header {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 0 32px;
}

.net-sales-chart-container {
    width: 100%;
    height: 440px;
    margin-top: 50px;
}

.value-cell {
    background-color: #E7EAEE;
    padding: 3px 8px;
    border-radius: 4px;
}

.tabular-data-container {
    display: flex;
    justify-content: center;
    width: 90%;
    height: 440px;
    margin: 100px 30px 0 30px;
}